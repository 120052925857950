import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/toast";
import PageHeader from "../../../../atoms/PageHeader";
import BackBtn from "../../../../atoms/BackBtn";
import PageCard from "../../../../atoms/PageCard";
import InputLayout from "../../../../atoms/InputLayout";
import Error from "../../../../atoms/Error";
import SelectDropdown from "../../../../molecules/Dropdown";
import { countryCodeData } from "../../../../../helpers/countryCodes";
import { businessTypes } from "../../../../../helpers/staticData";
import CustomButton from "../../../../atoms/CustomButton";
import { DateSelector } from "../../../../organisms/MonthYearSelector";
import { replaceSpacesInImageUrl } from "../../../../../helpers/replaceSpaceInImageUrl";
import ImageSelector from "../../../../organisms/ImageSelector/ImageSelector";
import { useCookies } from "react-cookie";
import {
  getData,
  postFormData,
  patchFormData,
} from "../../../../../services/index";
import dayjs from "dayjs";
import { statusData } from "../../../../../helpers/staticData";
import { checkEmailExists } from "../../../../../services/basicGets";
import { capitalizeLetter } from "../../../../../helpers/capatalizeLetters";

const ImageSelectorStyle = {
  borderRadius: "10px",
  border: "1px solid #EFEFEF",
  padding: "10px",
  width: "200px",
  objectFit: "contain",
};

const Add = ({ type = "add" }) => {
  const { id } = useParams();

  const [cookies] = useCookies(["t"]);
  const [plans, setPlans] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [emailError, setEmailError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const getPlans = async () => {
      const res = await getData({
        endpoint: "PlanManagement/getPlanList",
        token: cookies.t,
      });
      setPlans(res);
    };

    const fetchData = async () => {
      const res = await getData({
        endpoint: "BusinessManagement/getBusinessDetailsById",
        token: cookies.t,
        params: { id },
      });
      setInitialValues(res);
    };

    getPlans();

    if (type === "edit") {
      fetchData();
    }
  }, []);

  const initialData = {
    logo: initialValues?.logo || null,
    name: initialValues?.name || null,
    email: initialValues?.email || null,
    business_type: initialValues?.business_type || null,
    official_address: initialValues?.official_address || null,
    registered_address: initialValues?.registered_address || null,
    country_code: initialValues?.country_code || "+91",
    phone_number: initialValues?.phone_number || null,
    alternate_phone_number: initialValues?.alternate_phone_number || null,
    contact_country_code: initialValues?.contact_country_code || "+91",
    gst_number: initialValues?.gst_number || null,
    contact_phone: initialValues?.contact_phone_number || null,
    start_date: initialValues?.start_date || dayjs().format("YYYY-MM-DD"),
    end_date: initialValues?.end_date || null,
    plan_id: initialValues?.plan_id || null,
    contact_name: initialValues?.contact_name || null,
    is_active: initialValues?.is_active || false,
    contact_user_id: initialValues?.contact_user_id || null,
    subscription_id: initialValues?.subscription_id || null,
    period_day: initialValues?.period_day || null,
    period_month: initialValues?.period_month || null,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Business Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    business_type: Yup.string().required("Business Type is required"),
    country_code: Yup.string().required("Country code is required"),
    phone_number: Yup.string().when("country_code", {
      is: "+91", // Condition: when country_code is '+91'
      then: () =>
        Yup.string()
          .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
          .required("Primary Phone Number is required"),
      otherwise: () =>
        Yup.string()
          .matches(/^[0-9]{7,15}$/, "Please Enter a valid Phone Number")
          .required("Primary Phone Number is required"),
    }),
    alternate_phone_number: Yup.string()
      .nullable()
      .when("country_code", {
        is: "+91", // Condition: when country_code is '+91'
        then: () =>
          Yup.string()
            .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
            .nullable(),
        otherwise: () =>
          Yup.string()
            .matches(/^[0-9]{7,15}$/, "Please Enter a valid Phone Number")
            .nullable(),
      }),
    gst_number: Yup.string()
      .matches(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[Z]{1}[A-Z0-9]{1}$/,
        "Invalid GST number"
      )
      .nullable(),
    official_address: Yup.string().required("Official Address is required"),
    registered_address: Yup.string().required("Registered Address is required"),
    contact_name: Yup.string().required("Contact Person Name is required"),
    contact_phone: Yup.string().when("country_code", {
      is: "+91", // Condition: when country_code is '+91'
      then: () =>
        Yup.string()
          .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
          .required("Contact Person Number is required"),
      otherwise: () =>
        Yup.string()
          .matches(/^[0-9]{7,15}$/, "Please Enter a valid Phone Number")
          .required("Contact Person Number is required"),
    }),
    plan_id: Yup.string().required("Subscription Plan is required"),
    start_date: Yup.date().required("Subscription Start Date is required"),
    // end_date: Yup.date()
    //   .required("Subscription End Date is required")
    //   .min(Yup.ref("start_date"), "End Date cannot be before Start Date"),
  });

  const handleSubmit = async (values) => {
    setIsSaving(true);

    try {
      const res = await postFormData({
        endpoint: "BusinessManagement/addBusiness",
        token: cookies.t,
        data: {
          ...values,
          contact_name: capitalizeLetter(values.contact_name),
          start_date:
            values.start_date == initialData.start_date
              ? dayjs()
              : values.start_date,
        },
      });
      if (res) {
        toast.success("Business Created Sucessfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate(-1);
      }
    } catch (error) {
      toast.error("An Error Occured Please try again later", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleEdit = async (values) => {
    const data = {
      ...values,
      contact_name: capitalizeLetter(values.contact_name),
    };

    try {
      const res = await patchFormData({
        endpoint: "BusinessManagement/editBusiness",
        token: cookies.t,
        data: data,
        params: { business_id: id },
      });
      if (res) {
        toast.success("Business Updated Sucessfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate(-1);
      }
    } catch (error) {
      toast.error("An Error Occured Please try again later", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const checkEmail = async (newVal) => {
    const response = await checkEmailExists({
      email: newVal,
      token: cookies.t,
    });
    if (response.message) {
      setEmailError("Email Already Exists");
    } else {
      setEmailError(null);
    }
  };

  const handleEndDate = ({ start_date, values, setFieldValue }) => {

    const new_start = dayjs(start_date)
    let newDate = new_start
      .add(values.period_month, "month")
      .add(values.period_day, "day");

    const formatted = newDate.format("YYYY-MM-DDTHH:mm:ss");
    console.log('for',formatted)
    setFieldValue("end_date", formatted + "Z");
  };

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnBlur
      validateOnChange
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        handleBlur,
        validateForm,
      }) => (
        <>
          <PageHeader title={type === "add" ? "Add Business" : "Edit Business"}>
            <BackBtn />
          </PageHeader>

          <PageCard>
            <div className="row mb-3">
              <div className="col-md">
                <label htmlFor="">Logo</label>
                <ImageSelector
                  style={ImageSelectorStyle}
                  type="Company"
                  handleFile={(file) => {
                    setFieldValue("logo", file);
                    setFieldValue("remove_logo", false);
                  }}
                  aspect={2}
                  widthLimit={600}
                  heightLimit={400}
                  selectedImage={replaceSpacesInImageUrl(initialData?.logo)}
                  description="Logo image should be 600X400px and size is 1MB"
                  onRemove={() => {
                    setFieldValue("remove_logo", true);
                  }}
                />
              </div>

              <div className="col-md"></div>
            </div>
            <div className="row g-4">
              <InputLayout>
                <label htmlFor="name">Business Name</label>
                <Field
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter Business Name"
                  className={`${
                    touched.name && errors.name ? "error-input" : ""
                  }`}
                />
                {touched.name && errors.name && <Error error={errors.name} />}
              </InputLayout>
              <InputLayout>
                <label htmlFor="email">Email Id</label>
                <Field
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Enter Email"
                  className={`${
                    (touched.email && errors.email) || emailError
                      ? "error-input"
                      : ""
                  }`}
                  onBlur={(e) => {
                    checkEmail(values.email);
                    handleBlur(e);
                  }}
                />
                {((touched.email && errors.email) || emailError) && (
                  <Error error={errors.email || emailError} />
                )}
              </InputLayout>

              <InputLayout>
                <label htmlFor="business_type">Business Type</label>
                <SelectDropdown
                  placeholder="Select Business Type"
                  data={businessTypes}
                  handleBlur={(e) => {
                    setFieldTouched("business_type", true);
                    handleBlur(e);
                  }}
                  onChange={(option) => {
                    setFieldValue("business_type", option);
                  }}
                  isError={touched.business_type && errors.business_type}
                  selectedValue={initialData?.business_type}
                />
                {touched.business_type && errors.business_type && (
                  <Error error={errors.business_type} />
                )}
              </InputLayout>
            </div>

            {/* {console.log("erros", errors)} */}

            <div className="row g-4 mt-1">
              <InputLayout>
                <label htmlFor="">Primary Phone Number</label>
                <div className="d-flex gap-3">
                  <div className="col-4 col-lg-2">
                    <Field
                      type="text"
                      name="country_code"
                      className={`w-100 h-100 ${
                        touched.country_code && errors.country_code
                          ? "error-input"
                          : ""
                      }`}
                      disabled
                    />
                  </div>
                  <div className="col-lg-10 pe-0 col-9" style={{ flex: "auto" }}>
                    <Field
                      type="text"
                      name="phone_number"
                      placeholder="Enter Primary Phone Number"
                      className={`w-100 h-100 ${
                        touched.phone_number && errors.phone_number
                          ? "error-input"
                          : ""
                      }`}
                      onChange={(e) => {
                        let numericInput = e.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                        if (values.country_code === "+91") {
                          // Enforce a maximum length of 10 digits
                          if (numericInput.length > 10) {
                            numericInput = numericInput.slice(0, 10);
                          }
                          setFieldValue("phone_number", numericInput);
                        } else {
                          setFieldValue("phone_number", numericInput);
                        }
                      }}
                    />
                  </div>
                </div>

                {touched.phone_number && errors.phone_number && (
                  <Error error={errors.phone_number || errors.phone_number} />
                )}
              </InputLayout>

              <InputLayout>
                <label htmlFor="">Alternate Phone Number (Optional)</label>
                <div className="d-flex gap-3">
                  <div className="col-4 col-lg-2">
                    <Field
                      type="text"
                      name="country_code"
                      className={`w-100 h-100 ${
                        touched.country_code && errors.country_code
                          ? "error-input"
                          : ""
                      }`}
                      disabled
                    />
                  </div>
                  <div className="col-lg-10 pe-0 col-9" style={{ flex: "auto" }}>
                    <Field
                      type="text"
                      name="alternate_phone_number"
                      placeholder="Enter Alternate Phone Number"
                      className={`w-100 h-100 ${
                        touched.alternate_phone_number &&
                        errors.alternate_phone_number
                          ? "error-input"
                          : ""
                      }`}
                      onChange={(e) => {
                        let numericInput = e.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                        if (values.country_code === "+91") {
                          // Enforce a maximum length of 10 digits
                          if (numericInput.length > 10) {
                            numericInput = numericInput.slice(0, 10);
                          }
                          setFieldValue("alternate_phone_number", numericInput);
                        } else {
                          setFieldValue("alternate_phone_number", numericInput);
                        }
                      }}
                    />
                  </div>
                </div>
                {touched.alternate_phone_number &&
                  errors.alternate_phone_number && (
                    <Error
                      error={
                        errors.alternate_phone_number ||
                        errors.alternate_phone_number
                      }
                    />
                  )}
              </InputLayout>

              <InputLayout>
                <label htmlFor="gst">GST No. (Optional)</label>
                <Field
                  type="text"
                  id="gst_number"
                  name="gst_number"
                  placeholder="Enter GST No."
                  className={`${
                    touched.gst_number && errors.gst_number ? "error-input" : ""
                  }`}
                />
                {touched.gst_number && errors.gst_number && (
                  <Error error={errors.gst_number} />
                )}
              </InputLayout>
            </div>

            {console.log('err',errors)}

            <div className="row g-4 mt-1 mb-4">
              <InputLayout>
                <label htmlFor="">Official Address</label>
                <Field
                  type="text"
                  as="textarea"
                  id="official_address"
                  name="official_address"
                  placeholder="Enter Official Address"
                  className={`${
                    touched.official_address && errors.official_address
                      ? "error-input"
                      : ""
                  }`}
                />
                {touched.official_address && errors.official_address && (
                  <Error error={errors.official_address} />
                )}
              </InputLayout>

              {console.log('val',values)}

              <InputLayout>
                <label htmlFor="gst">Registered Address</label>
                <Field
                  type="text"
                  as="textarea"
                  id="registered_address"
                  name="registered_address"
                  placeholder="Enter Registered Address"
                  className={`${
                    touched.registered_address && errors.registered_address
                      ? "error-input"
                      : ""
                  }`}
                />
                {touched.registered_address && errors.registered_address && (
                  <Error error={errors.registered_address} />
                )}
              </InputLayout>
            </div>

            <h4 className="fw-bolder">Contact Details</h4>
            <hr />

            <div className="row g-4 mb-4">
              <InputLayout>
                <label htmlFor="contact_name">Contact Person Name</label>
                <Field
                  type="text"
                  id="contact_name"
                  name="contact_name"
                  placeholder="Enter Contact Person Name"
                  className={`${
                    touched.contact_name && errors.contact_name
                      ? "error-input"
                      : ""
                  }`}
                />
                {touched.contact_name && errors.contact_name && (
                  <Error error={errors.contact_name} />
                )}
              </InputLayout>

              <InputLayout>
                <label htmlFor="">Contact Person Number</label>
                <div className="d-flex gap-3">
                  <div className="col-4 col-lg-2">
                    <Field
                      type="text"
                      name="country_code"
                      className={`w-100 h-100 ${
                        touched.contact_country_code &&
                        errors.contact_country_code
                          ? "error-input"
                          : ""
                      }`}
                      disabled
                    />
                  </div>
                  <div className="col-lg-10 pe-0 col-9" style={{ flex: "auto" }}>
                    <Field
                      type="text"
                      name="contact_phone"
                      placeholder="Enter Contact Number"
                      className={`w-100 h-100 ${
                        touched.contact_phone && errors.contact_phone
                          ? "error-input"
                          : ""
                      }`}
                      onChange={(e) => {
                        let numericInput = e.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                        if (values.contact_country_code === "+91") {
                          if (numericInput.length > 10) {
                            numericInput = numericInput.slice(0, 10);
                          }
                          setFieldValue("contact_phone", numericInput);
                        } else {
                          setFieldValue("contact_phone", numericInput);
                        }
                      }}
                    />
                  </div>
                </div>

                {touched.contact_phone && errors.contact_phone && (
                  <Error error={errors.contact_phone || errors.contact_phone} />
                )}
              </InputLayout>

              <InputLayout></InputLayout>
            </div>

            <h4 className="fw-bolder">Subscription Details</h4>
            <hr />

            <div className="row g-4">
              <InputLayout>
                <label htmlFor="">Select Plan</label>
                <SelectDropdown
                  placeholder="Select Subscription Plan"
                  type={type}
                  menuPlacement="top"
                  data={plans}
                  onChange={(option) => {
                    // console.log("op", option);
                    setFieldValue("plan_id", option?.value);
                    setFieldValue("period_month", option.period_month);
                    setFieldValue("period_day", option.period_day);

                    console.log('op',option)

                    let currentDate = dayjs(values?.start_date) || dayjs();
                    let newDate = currentDate
                      .add(option.period_month, "month")
                      .add(option.period_day, "day");

                    

                    console.log('new', newDate)

                    debugger
                    setFieldValue("end_date", newDate);
                    values.end_date = newDate;
                    validateForm({
                      ...values,
                      end_date: newDate,
                      plan_id: option?.value,
                    });
                  }}
                  handleBlur={(e) => {
                    setFieldTouched("plan_id", true);
                    handleBlur(e);
                  }}
                  isError={touched.plan_id && errors.plan_id}
                  selectedValue={initialData?.plan_id}
                  format="all"
                />
                {touched.plan_id && errors.plan_id && (
                  <Error error={errors.plan_id} />
                )}
              </InputLayout>

              <InputLayout>
                <label htmlFor="">Subscription Start Date</label>
                <DateSelector
                  placeholder="Select Start Date"
                  onChange={(date) => {
                    setFieldValue("start_date", date);
                    handleEndDate({
                      start_date: dayjs(date),
                      values,
                      setFieldValue,
                    });
                  }}
                  selectedDate={initialData?.start_date}
                  handleBlur={(e) => {
                    setFieldTouched("start_date", true);
                    handleBlur(e);
                  }}
                  // disablePast
                  // minDateData={values?.start_date}
                  // maxDateData={values?.end_date}
                  isError={touched.start_date && errors.start_date}
                />
                {touched.start_date && errors.start_date && (
                  <Error error={errors.start_date} />
                )}
              </InputLayout>

              <InputLayout>
                <label htmlFor="">Subscription End Date</label>
                <DateSelector
                  placeholder="Select End Date"
                  onChange={(date) => setFieldValue("end_date", date)}
                  selectedDate={values?.end_date}
                  handleBlur={(e) => {
                    setFieldTouched("end_date", true);
                    handleBlur(e);
                  }}
                  minDateData={values.min_date}
                  isError={touched?.end_date && errors?.end_date}
                  disabled={true}
                />

                {touched.end_date && errors.end_date && (
                  <Error error={errors.end_date} />
                )}
              </InputLayout>
            </div>
            {type === "edit" && (
              <div className="row g-4 mt-1">
                <InputLayout>
                  <label htmlFor="">Status</label>
                  <SelectDropdown
                    data={statusData}
                    placeholder="Select Status"
                    onChange={(option) => {
                      setFieldValue("is_active", option);
                    }}
                    selectedValue={initialData?.is_active}
                  />
                </InputLayout>

                <InputLayout></InputLayout>

                <InputLayout></InputLayout>
              </div>
            )}

            <div className="d-flex gap-3 mt-4">
              {type === "add" ? (
                <CustomButton
                  iconRequired={false}
                  type="btn-primary"
                  buttonType="submit"
                  handleClick={() => handleSubmit(values)}
                  disabled={
                    !isValid || !dirty || isSaving || emailError !== null
                  }
                >
                  Create Business
                  {isSaving && (
                    <span
                      className="spinner-border spinner-border-sm ms-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </CustomButton>
              ) : (
                <CustomButton
                  iconRequired={false}
                  type="btn-primary"
                  buttonType="submit"
                  handleClick={() => handleEdit(values)}
                  disabled={!isValid || isSaving || emailError}
                >
                  Edit Business
                  {isSaving && (
                    <span
                      className="spinner-border spinner-border-sm ms-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </CustomButton>
              )}

              <CustomButton
                text="Cancel"
                iconRequired={false}
                handleClick={() => {
                  navigate(-1);
                }}
              />
            </div>
          </PageCard>
        </>
      )}
    </Formik>
  );
};

export default Add;
