import { InputAdornment, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from "dayjs";
import "./datepicker.scss";

const datePickerStyle = {
  height: "52px", // Set your desired height
  backgroundColor: "lightblue", // Set your desired background color
  border: "2px solid darkblue", // Set your desired border
};

// ... (imports remain the same)

export function MonthYearSelector({
  onChange,
  placeholder = "Select",
  selectedDate,
  disableFuture = false,
  disablePast = false,
  minDateData,
  maxDateData,
  handleBlur
  // IsOpen,
  // setIsOpen
}) {
  const handleDateChange = (date) => {
    if (onChange) {
      onChange(date.format("YYYY-MM"));
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="calender-input-component" tabIndex={0}>
        <DatePicker
          disableFuture={disableFuture}
          disablePast={disablePast}
          openTo="month"
          views={["year", "month"]}
          style={datePickerStyle}
          onClose={handleBlur}
          onOpen={() =>  window.alert('heleo')}
          // open={IsOpen}
          minDate={minDateData ? dayjs(minDateData) : undefined} // Use minDate if available
          maxDate={maxDateData ? dayjs(maxDateData) : undefined} // Use minDate if available
          onChange={handleDateChange}
          value={selectedDate ? dayjs(selectedDate) : null} // Set the initially selected date
          slotProps={{
            textField: { placeholder: `${placeholder}` , onBlur :  handleBlur},
            field: {
              readOnly: true,
              onBlur : handleBlur
              
            },
          }}
          // sx={{
          //   "&. MuiTextField-root": {
          //     width: "100% !important",
          //   },
          //   "&. MuiFormControl-root-MuiTextField-root": {
          //     width: "100% !important",
          //   },
          // }}
          className="w-100"
        />
      </div>
    </LocalizationProvider>
  );
}
// Extend dayjs with timezone and utc plugins
dayjs.extend(utc);
dayjs.extend(timezone);

export function DateSelector({
  onChange,
  placeholder = "Select Date",
  disableFuture = false,
  disablePast = false,
  disabled = false,
  selectedDate,
  minDateData,
  maxDateData,
  handleBlur,
  isError
}) {
  const handleDateChange = (date) => {
    if (onChange && date) {

      console.log('date-picker',date)
      // Format the selected date to include the time part in ISO format
      // const isoDate = date.format("YYYY-MM-DDTHH:mm:ss"); 
      // onChange(isoDate + "Z"); // Append 'Z' for UTC timezone

      onChange(date)
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className={`calender-input-component ${isError ? 'error-container' : ''}`} tabIndex={0}>
        <DatePicker
          disableFuture={disableFuture}
          disablePast={disablePast}
          style={datePickerStyle}
          slotProps={{
            textField: { placeholder: `${placeholder}`, onBlur: handleBlur },
            field: {
              readOnly: true,
            },
          }}
          format="DD/MM/YYYY"
          onChange={handleDateChange}
          disabled={disabled}
          // Display the selected date, preserving the time part internally
          value={selectedDate ? dayjs(selectedDate) : null}
          minDate={minDateData ? dayjs(minDateData).startOf('day') : undefined}
          maxDate={maxDateData ? dayjs(maxDateData).startOf('day') : undefined}
          className="w-100"
        />
      </div>
    </LocalizationProvider>
  );
}


